import React, { Component } from "react";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div>
            {/* <div className="col-xs-12 col-md-6">
              <img src="img/about.jpg" className="img-responsive" alt="" />
            </div> */}
            <div className="col-xs-12 col-md-12">
              <div
                className="col-xs-12 col-md-6"
                style={{ padding: "0px 15px 5px 0px" }}
              >
                <img
                  src="img/about-1.png"
                  className="img-responsive"
                  alt="Software Development"
                />
              </div>
              <div style={{ padding: "0px" }} className="col-xs-12 col-md-6">
                <h2>About Us</h2>
              </div>
              <div className="about-text">
                <p>
                  Muhsee Technologies is an Indian software development company.
                  We assist startups and enterprises in their digital
                  transformation journey via our myriad of development services
                  offered. With an aim to be a centre of par excellence, our
                  core fundamentals are highest quality and utmost customer
                  satisfaction.
                  <br />
                  <br />
                  Our team advances custom tailor made development services for
                  your requirement including mobile apps, web apps, software and
                  UI UX development with a goal of adding value to your
                  business. With premium results assured, Muhsee Technologies
                  aids in the metamorphosis of your idea to a flourishing
                  business.
                  <br />
                  <br />
                  Our methodology includes analysing the core functionality of
                  your company, whether it is a startup, Small to Medium
                  Business (SMB), Small to Medium Enterprise (SME) or a Large
                  Enterprises for a curated approach. With a handpicked team of
                  experts and professionals, cogent and measured solutions are
                  ideated according to your needs. Usage of cutting edge
                  technology to find innovative solutions to complex problems is
                  one of the foundational pillars of our company.
                </p>
                {/* <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
