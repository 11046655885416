import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>

                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.google.com/maps/place/144,+Ranipura+Main+Rd,+Siyaganj,+Indore,+Madhya+Pradesh+452007/@22.7172228,75.8606917,17z/data=!3m1!4b1!4m5!3m4!1s0x3962fd0e656f7337:0x33f5a2fb2d334065!8m2!3d22.7172228!4d75.8628804"
                  >
                    {/* 3<sup>rd</sup> Floor, 144 Ranipura Main Road, Indore, M.P.
                    452007 - India */}
                    144/2-145 Ranipura, Indore, Madhya Pradesh, India,
                    452007
                  </a>
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  <a href="tel:+919039693852">+91 9039693852</a>
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  <a href="mailto:info@muhsee.com">info@muhsee.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div
            className="container text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ width: "90%" }}>
              Copyright &copy; 2021 Muhsee Technologies Pvt. Ltd. - All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
