import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <p>
              Overcome your business challenges with our services involving
              cutting-edge technology. From mobile application, web application,
              software development, UI & UX to DevOps and QA, we cater to your
              needs so that you can reduce costs and achieve unlimited growth.
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-6">
                    {" "}
                    <i className={d.icon}></i>
                    <div style={{ minHeight: 250 }} className="service-desc">
                      <h3>{d.name}</h3>
                      <p style={{ textAlign: "left" }}>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
